@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

@font-face {
    font-family: 'CrownFontEE';
    src: url('./assets/fonts/CrownFont-Regular.eot');
    src: url('./assets/fonts/CrownFont-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/CrownFont-Regular.woff2') format('woff2'),
        url('./assets/fonts/CrownFont-Regular.woff') format('woff'),
        url('./assets/fonts/CrownFont-Regular.ttf') format('truetype'),
        url('./assets/fonts/CrownFont-Regular.svg#CrownFontEE') format('svg');
    font-weight: normal;
    font-style: normal;
    letter-spacing: 5px;
}

@font-face {
    font-family: 'AllStarResort';
    src: url('./assets/fonts/AllStarResort.eot');
    src: url('./assets/fonts/AllStarResort.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/AllStarResort.woff2') format('woff2'),
        url('./assets/fonts/AllStarResort.woff') format('woff'),
        url('./assets/fonts/AllStarResort.ttf') format('truetype'),
        url('./assets/fonts/AllStarResort.svg#AllStarResort') format('svg');
    font-weight: normal;
    font-style: normal;
    letter-spacing: 5px;
}

@font-face {
    font-family: 'Script';
    src: url('./assets/fonts/script_2022-webfont.eot');
    src: url('./assets/fonts/script_2022-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/script_2022-webfont.woff2') format('woff2'),
    url('./assets/fonts/script_2022-webfont.woff') format('woff'),
    url('./assets/fonts/Script2022.ttf') format('truetype'),
    url('./assets/fonts/script_2022-webfont.svg#AllStarResort') format('svg');
    font-weight: normal;
    font-style: normal;
    letter-spacing: 5px;
}

* {
    font-family: Roboto;
}

html {
    min-height: 100%;
    display: flex;
}

body {
    padding-top: 0;
    min-height: 100%;
    width: 100%;
}

#root {
    display: flex;
}

#root, #root>.themed-root  {
    min-height: 100%;
    width: 100%;
}

#root>.themed-root>.MuiContainer-root {
    height: auto;
}


body form._inline-form input[data-name='ajat_school_id'] {
    display: none;
}

body > div > form._inline-form {
    display: none;
}

/* tracking pixels positioning */
body > img {
    position: fixed;
}


._full_width input#schoolAuto {
    margin-bottom: 0 !important;
    border: none !important;
    height: 1.1876em !important;
    padding: 6px 0 7px !important;
    box-sizing: content-box!important;
    padding-left: 25px !important;
    padding-top: 15px !important;
}
._full_width svg#Layer_1 {
    top: 23px;
    position: relative;
}
div[name="schoolAuto"] {
    padding-bottom: 15px !important;
}

div[name="schoolAuto"] button.MuiAutocomplete-clearIndicator {
    margin-top: 5px;
}